@import 'global.css';
@import 'typography.css';
@import 'overrides.css';
@import 'consent-manager.css';
@import 'loading.css';
/*
Temp CSS fixes
*/

.Box{
  padding: 32px;
}
.ProfileMain__info-field{
  margin-bottom: calc(2*0.5rem);
  margin-bottom: calc(2*0.5rem);
  margin-bottom: var(--space-md);
}

body{
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu','Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif';
}

@keyframes animationSpin {
  from{
    -webkit-transform: rotate(0deg);
  }
  to{
    -webkit-transform: rotate(360deg);
  }
}
