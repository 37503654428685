html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
}
.ui.page.dimmer{
  padding-left:0;
}
.getro-bg{
  background-color: inherit!important;;
}
