.searchSplit label {
  font-weight: var(--text-regular);
  color: var(--color-text);
  display: inline-block;
  width: calc(100% / 4);
}

@media (min-width: 768px) {
  .searchSplit label {
    width: calc(100% / 4)
  }
}

.searchSplit > div > div {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .searchSplit > div > :nth-child(3) {
    padding-left: calc(100% / 4)
  }
}

.searchSplit > div > div {
  display: inline-block;
  width: 100%
}

@media (min-width: 768px) {
  .searchSplit > div > div {
    width: calc((100% / 4) * 3)
  }
}
