@media screen and (min-width: 52em) {
  #cconsent-bar{
    left: 16px!important;
    width: 400px!important;
  }
  #cconsent-bar:not(.ccb--hidden){
    bottom: 16px!important;
  }
}

#cconsent-modal{
  font-family: inherit!important;
}

#cconsent-bar {
  font-family: inherit!important;
}

#cconsent-bar{
  font-family: inherit!important;
  right: auto;
  max-width: 100%;
  border-radius: 3px;
  position: fixed;
  z-index: 71;
  background-color: #090925;
  font-size: 14px;
  color: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1), 0 8px 24px rgba(0, 0, 0, 0.15);
}

#cconsent-modal button.consent-reject,
#cconsent-bar button.consent-reject{
  display: none;
}
#cconsent-bar .ccb__right{
  padding-top:16px;
  width: 100%;
}
#cconsent-bar .ccb__left{
  width: 100%;
  margin:0!important;
}

#cconsent-modal #ccm__footer__consent-modal-submit{
  display: none;
}

#cconsent-bar .cc-text{
  font-weight: 400!important;
  font-size: 15px;
}
#cconsent-bar a.ccb__edit{
  line-height: normal;
  font-weight: 600;
  font-size: inherit!important;
  margin-left: auto;
  border: 1px solid #A6B1BF;
  border-radius: 4px;
  color: #4C5566!important;
  text-decoration: none!important;
  padding:8px 16px;
}

#cconsent-bar .ccb__right .ccb__button{
  display: flex;
  font-size: 13px;
  align-content: stretch;
}
#cconsent-modal button.consent-give,
#cconsent-bar button.consent-give{
  border: none!important;
  padding: 8px 16px!important;
  margin: 0 0 0 16px;
}
