a,
.link {
  text-decoration: underline;
  cursor: pointer;
}

button.link {
  appearance: none;
  border: none;
  background: none;
  text-decoration: underline;
  cursor: pointer;
  font-weight: var(--text-regular);
  padding: 0;
  line-height: var(--space-md);
  height: var(--space-md);
}
